<script lang="ts">
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";
import {TokenContractApi} from "@/blockchain/token-contract.api";
import {Web3Helper} from "@/blockchain/web3-helper";
import {sleep} from "@/helper";
import Web3 from "web3";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "Admin",
  components: {Header},
  data()
  {
    return {
      web3: Web3,
      chests: [],
      chestTokens: '',
      chestPrice: '',
      chestTotalForSale: '',
      chestIsActive: true,
      newAffiliateCommission: '',
      affiliateCommission: '',
      customNewAffiliateCommission: '',
      customAffiliateCommission: '',
      customAffiliateAddress: '',

      platform: 'desktop',
      tokenApi: <TokenContractApi><unknown>null,
      isAdmin: false,
      currentNativeBalance: <number | null>null,
      withdrawAmount: '',
      itoTotalAmount:0,
      itoDetails: {
        itoIsActive: false,
        itoPercents: <number[]>[],
        itoTotalAmount: <number[]>[],
        itoRates: [],
        itoRemainingAmounts: <number[]>[],
        itoSoldAmounts: <number[]>[],
        itoSoldPercents: <number[]>[],
        itoCurrentLevel: 0
      },
      stakeDetails: <{ address: string, share: number | null }[]>[],
      stakeItems: <{ address: string, share: string }[]>[
        {address: '0x9E80E37b47A871b004803714dAf2fD10D7dEbef1', share: '60'},
        {address: '0x898afD21c31C85a9dF0Ca6cf37568B8B3794C44c', share: '40'}
      ],

      airdropBalance:0,
      targetAirdropAmount:0,
      targetAirdropAddress:''
    }
  },
  mounted()
  {
    const window_width = window.innerWidth;
    if (window_width < 650)
      this.platform = 'mobile';
    this.initiate();

    // @ts-ignore
    this.emitter.on('web3Changed', (data) =>
    {
      this.initiate()
    });
  },
  computed: {
    ...mapGetters('web3', {
      isWeb3Available: 'isWeb3Available',
      isWeb3Injected: 'isWeb3Injected',
      currentAccount: 'getCurrentAccount',
      currentNetworkId: 'getCurrentNetworkId',
      isConnectedToRightNetwork: 'isConnectedToRightNetwork',
    })
  },
  methods: {
    toSolidityNumberWithDecimals(number,decimals)
    {
      if (typeof number !== "number" || isNaN(number))
      {
        throw new Error("Input must be a valid number.");
      }

      // Convert the number to a string with 6 decimal places
      let numberWithDecimals = number.toFixed(decimals);

      // Remove the decimal point for Solidity compatibility
      let solidityNumber = numberWithDecimals.replace(".", "");

      return solidityNumber;
    },
    async addChest()
    {
      if (!this.chestTotalForSale || !this.chestPrice || !this.chestTokens)
      {
        //@ts-ignore
        this.$swal('Please fill all fields');
        return;
      }
      if (!this.web3)
        return;
      const price = this.toSolidityNumberWithDecimals(this.chestPrice,18)
      const tokens = (parseFloat(this.chestTokens) * Math.pow(10, 8)).toString();
      const r = await this.tokenApi.addChest(tokens, price, this.chestTotalForSale, this.chestIsActive);
      if (r)
      {
        //@ts-ignore
        this.$swal('Done');
        this.getChests()
      }
      else
      {
        //@ts-ignore
        this.$swal('Error');
      }
    },
    async withdraw()
    {
      let amount = Number(this.withdrawAmount);
      if (isNaN(amount) || amount < 0)
      {
        alert('invalid amount')
        return;
      }

      if (!this.currentNativeBalance || amount > this.currentNativeBalance)
      {
        alert('amount is more than balance')
        return;
      }
      const r = await this.tokenApi.withdraw(amount);
      if (r)
        alert('Done');
      else
        alert('error happened');
    },
    async initiate()
    {
      await sleep(1800);
      if (this.isWeb3Injected && this.isConnectedToRightNetwork)
      {
        const web3 = new Web3(Web3Helper.getWindowWeb3());

        // @ts-ignore
        this.web3 = web3;
        // @ts-ignore
        window.www = web3;
        this.tokenApi = new TokenContractApi(web3);
        console.log('Account: ',await this.tokenApi.getCurrentAccount())
        this.isAdmin = await this.tokenApi.isAuthorized(await this.tokenApi.getCurrentAccount());
        console.log('admin: ',this.isAdmin)
        if (this.isAdmin)
        {
          console.log('Referrer: ', await this.tokenApi.getReferrer('0x898afD21c31C85a9dF0Ca6cf37568B8B3794C44c'))
          this.getChests();
          this.getItoDetails();
          this.setStakeDetails();

          this.updateAirdropBalance();

          this.tokenApi.getNativeBalance(this.tokenApi.contractAddress).then((balance) =>
          {
            if (balance)
              this.currentNativeBalance = Number(balance) / (10 ** 18);
          });

          this.getGeneralAffiliateCommission();
        }
      }
    },
    async getChests()
    {
      this.tokenApi.getChests().then((r) =>
      {
        if (r)
        {
          this.chests = r;
        }
      })
    },
    async deactivateIto()
    {
      if (!this.itoDetails.itoIsActive)
      {
        alert('ITO is already deactivated');
        return;
      }
      const r = await this.tokenApi.deactivateIto();
      if (r)
      {
        console.log('Done');
        this.getItoDetails();
      }
      else
        console.log('Error')
    },
    async activateIto()
    {
      if (this.itoDetails.itoIsActive)
      {
        alert('ITO is already activated');
        return;
      }

      const r = await this.tokenApi.activateIto();
      if (r)
      {
        console.log('Done');
        this.getItoDetails();
      }
      else
        console.log('Error')
    },
    enableWeb3()
    {
      Web3Helper.enableWeb3()
    },
    async getItoDetails()
    {
      const info = await this.tokenApi.getItoDetails();
      console.log('Info: ', info)
      if (info)
      {
        this.itoTotalAmount = Number(info.totalItoAmount)/(10**8);
        const data = {
          itoIsActive: info.itoIsActive,
          itoPercents: info.itoPercents,
          itoTotalAmount: <number[]>[],
          itoRates: info.itoRates,
          itoRemainingAmounts: info.itoRemainingAmounts,
          itoSoldAmounts: <number[]>[],
          itoSoldPercents: <number[]>[],
          itoCurrentLevel: info.itoCurrentLevel
        }
        console.log('TR: ',this.itoTotalAmount)
        console.log('TT: ',JSON.stringify(info.itoPercents))
        console.log('TR: ',JSON.stringify(info.itoRemainingAmounts))
        for (let i = 0; i < info.itoPercents.length; i++)
        {
          data.itoPercents[i] = Number(info.itoPercents[i]);
          data.itoRemainingAmounts[i] = Number(info.itoRemainingAmounts[i]) / (10 ** 8);
          data.itoTotalAmount[i] = this.itoTotalAmount * data.itoPercents[i] / 100;
          data.itoSoldAmounts[i] = data.itoTotalAmount[i] - data.itoRemainingAmounts[i];
          data.itoSoldPercents[i] = Math.round(data.itoSoldAmounts[i] / this.itoTotalAmount * 10000) / 100;
          //
          // console.log('Total: ',data.itoTotalAmount[i])
          // console.log('Remaining: ',data.itoRemainingAmounts[i])
        }
        this.itoDetails = data;
      }
    },
    async setStakeDetails()
    {
      const info = await this.tokenApi.getStakeInfo();
      if (info)
      {
        this.stakeDetails = info;
      }
    },
    async getGeneralAffiliateCommission()
    {
      const info = await this.tokenApi.affiliateCommission();
      if (info)
      {
        this.affiliateCommission = info;
      }
    },
    async getCustomAffiliateCommission()
    {
      if (!this.tokenApi.web3.utils.isAddress(this.customAffiliateAddress))
      {
        alert('invalid address');
        return;
      }

      const info = await this.tokenApi.getSpecialCommission(this.customAffiliateAddress);
      console.log('i: ', info)
      if (info)
      {
        this.customAffiliateCommission = info;
      }
    },
    async setCustomAffiliateCommission()
    {
      if (!this.tokenApi.web3.utils.isAddress(this.customAffiliateAddress))
      {
        alert('invalid address');
        return;
      }
      const p = Number(this.customNewAffiliateCommission);
      if (isNaN(p) || p < 0 || p > 100)
      {
        alert('invalid percent amount');
        return;
      }

      console.log(this.customAffiliateAddress, p);
      const info = await this.tokenApi.setSpecialCommission(this.customAffiliateAddress, p);
      if (info)
      {
        this.customAffiliateCommission = this.customNewAffiliateCommission;
        alert('Done')
      }
      else
      {
        alert('Error')
      }
    },
    async setGeneralAffiliateCommission()
    {
      const p = Number(this.newAffiliateCommission);
      if (isNaN(p) || p < 0 || p > 100)
      {
        alert('invalid percent amount');
        return;
      }

      const info = await this.tokenApi.setAffiliateCommission(p);
      if (info)
      {
        this.affiliateCommission = this.newAffiliateCommission;
        alert('Done')
      }
      else
      {
        alert('Error')
      }
    },
    addNewStakeItem()
    {
      this.stakeItems.push({address: '', share: ''})
    },
    removeStakeItem(index)
    {
      this.stakeItems.splice(index, 1);
    },
    async registerStakeItems()
    {
      alert(1)
      let total = 0;
      const wallets: any[] = [], shares: any[] = [];
      for (let i = 0; i < this.stakeItems.length; i++)
      {
        if (isNaN(Number(this.stakeItems[i].share)))
        {
          alert('invalid share percent')
          return;
        }
        total += Number(this.stakeItems[i].share);
        wallets.push(this.stakeItems[i].address);
        shares.push(this.stakeItems[i].share);
      }
      alert(2)
      if (total !== 100)
      {
        alert('invalid total shares')
        return;
      }

      const r = await this.tokenApi.setStakeWallets(wallets, shares);
      if (r)
      {
        this.initiate();
        alert('Done')
      }
      else
        alert('error happened')
    },
    async updateAirdropBalance()
    {
      const r = await this.tokenApi.airdropRemaining();
      if (r)
        this.airdropBalance = Number(r)/(10**8);
    },
    async sendAirdrop()
    {
      let amount = Number(this.targetAirdropAmount);
      if (isNaN(amount) || amount < 0)
      {
        alert('invalid amount')
        return;
      }

      if (!this.targetAirdropAddress || !this.tokenApi.web3.utils.isAddress(this.targetAirdropAddress)) {
        alert('invalid address');
        return;
      }

      if (!this.airdropBalance || amount > this.targetAirdropAmount)
      {
        alert('amount is more than balance')
        return;
      }

      const r = await this.tokenApi.transferAirdrop(this.targetAirdropAddress,amount);
      if (r)
      {
        alert('Done');
        this.updateAirdropBalance()
      }
      else
        alert('error happened');
    }
  }
})
</script>

<template>
  <Header/>
  <main style="position:relative; z-index: 10000">
    <div class="container">
      <div v-if="!isWeb3Available" class="header-user order-lg-last text-lg-right">
        <button type="button" class="btn btn-gradient" data-toggle="modal" data-target="#installMetaMaskModal">
          Please install Metamask
        </button>
      </div>
      <div v-else-if="!isWeb3Injected" class="header-user order-lg-last text-lg-right">
        <button type="button" class="btn btn-gradient" @click="enableWeb3">Connect Wallet</button>
      </div>
      <div v-else-if="!isConnectedToRightNetwork" class="header-user order-lg-last text-lg-right">
        <button type="button" class="btn btn-gradient" data-toggle="modal" data-target="#wrongNetworkModal">Wrong
          Network
        </button>
      </div>
      <div v-else>
        <div v-if="isAdmin">
          <section id="manage-stake-address">
            <h2>Manage Stake Addresses</h2>
            <div v-if="stakeDetails && stakeDetails.length">
              <div class="alert alert-danger">Stake info already set</div>
              <div v-for="(stake,index) in stakeDetails" :key="index">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <strong>Address: </strong><span>{{ stake.address }}</span>
                  </div>
                  <div class="col-md-6 col-12">
                    <strong>Share: </strong><span>{{ stake.share }}%</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="alert alert-info">Stake wallets should be set</div>
              <div v-for="(item,index) in stakeItems" :key="index" class="row">
                <div class="col-md-6 col-12">
                  <input class="form-control" type="text" placeholder="address" v-model="item.address"/>
                </div>
                <div class="col-md-3 col-12">
                  <input class="form-control" type="text" placeholder="share in percent" v-model="item.share"/>
                </div>
                <div class="col-md-3 col-12">
                  <button @click="removeStakeItem(index)">Remove</button>
                </div>
              </div>
              <br/>
              <button @click="addNewStakeItem()">Add New Item</button>
              <br/>
              <button @click="registerStakeItems()">Register On blockchain</button>
            </div>
          </section>

          <br/>
          <hr/>
          <section id="manage-ito">
            <h2>ITO Info</h2>
            <div class="row">
              <div class="col-md-6 col-12">
                <strong>Current Level:</strong>
                <span>{{ itoDetails.itoCurrentLevel }}</span>
              </div>
              <div class="col-md-6 col-12">
                <strong>is ITO active: </strong>
                <span
                    v-html="(itoDetails.itoIsActive ? '<span class=\'text-success\'>Yes</span>':'<span class=\'text-danger\'>No</span>')"></span>
              </div>
            </div>
            <br/>
            <h2>ITO Details</h2>
            <div class="table-responsive table-striped">
              <table class="table table-bordered w-100">
                <thead>
                <tr>
                  <th>ITO level</th>
                  <th>ITO Percent</th>
                  <th>ITO Total Amount</th>
                  <th>Sell Rate</th>
                  <th>Remaining Amount</th>
                  <th>Sold Amount</th>
                  <th>Sold Percent</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in itoDetails.itoPercents.length" :key="i">
                  <td>{{ i }}</td>
                  <td>{{ itoDetails.itoPercents[i - 1] }}%</td>
                  <td>{{ itoDetails.itoTotalAmount[i - 1] }}</td>
                  <td>{{ itoDetails.itoRates[i - 1] }}</td>
                  <td>{{ itoDetails.itoRemainingAmounts[i - 1] }}</td>
                  <td>{{ itoDetails.itoSoldAmounts[i - 1] }}</td>
                  <td>{{ itoDetails.itoSoldPercents[i - 1] }} %</td>
                </tr>
                </tbody>
              </table>
            </div>

            <br/>
            <hr/>
            <h2>Active / Deactivate ITO</h2>
            <button class="btn btn-danger" v-if="itoDetails.itoIsActive" @click="deactivateIto()">Deactivate ITO
            </button>
            <button class="btn btn-success" v-else @click="activateIto()">Activate ITO</button>
          </section>

          <br/>
          <hr/>
          <section>
            <h2>Manage Chests:</h2>
            <h3>Chests List:</h3>
            <table class="w-100 table table-bordered">
              <tr>
                <th>#</th>
                <th>id</th>
                <th>tokens</th>
                <th>price</th>
                <th>remaining</th>
                <th>sold</th>
                <th>isActive</th>
              </tr>
              <tr v-for="(chest,index) in chests" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ chest.id }}</td>
                <td>{{ chest.tokens / (10 ** 8) }}</td>
                <td>{{ Math.round(chest.price / (10 ** 14)) / 10000 }}</td>
                <td>{{ chest.remaining }}</td>
                <td>{{ chest.sold }}</td>
                <td>
                  <span class="text-success" v-if="chest.isActive">Yes</span>
                  <span class="text-danger" v-else>No</span>
                </td>
              </tr>
            </table>
            <br/>
            <br/>
            <h3>Add Chest:</h3>
            <div class="row">
              <div class="col mb-2 col-md-6 col-12">
                <input class="form-control" type="number" v-model="chestTokens" placeholder="Tokens"/>
              </div>
              <div class="col mb-2 col-md-6 col-12">
                <input class="form-control" type="number" v-model="chestPrice" placeholder="Price"/>
              </div>
              <div class="col mb-2 col-md-6 col-12">
                <input class="form-control" type="number" v-model="chestTotalForSale" placeholder="Total For Sale"/>
              </div>
              <div class="col mb-2 col-md-6 col-12">
                Is Active: &nbsp;&nbsp;&nbsp;&nbsp;
                Yes: <input type="radio" name="chestIsActive" value="yes" @click="chestIsActive=true"
                            :checked="chestIsActive" placeholder="Is Active"/>
                &nbsp;&nbsp;
                No: <input type="radio" name="chestIsActive" value="no" @click="chestIsActive=false"
                           :checked="!chestIsActive" placeholder="Is Active"/>
              </div>
            </div>
            <div class="text-center">
              <button type="button" class="btn btn-info" @click="addChest">Add Chest</button>
            </div>
            <br/>
          </section>

          <br/>
          <hr/>
          <section>
            <h2>Withdraw:</h2>
            <div class="row">
              <div class="col-md-6 col-12">
                <strong>Current Balance: </strong>
                <span>{{ currentNativeBalance ? currentNativeBalance : '' }}</span>
              </div>
              <div class="col-md-6 col-12">
                <div class="mb-2">
                  <input class="form-control" placeholder="amount to withdraw" v-model="withdrawAmount"/>
                </div>
                <button class="btn btn-info" type="button" @click="withdraw()">Withdraw</button>
              </div>
            </div>
          </section>

          <br/>
          <hr/>
          <section>
            <h2>Manage Airdrop:</h2>
            <div class="row">
              <div class="col-md-6 col-12">
                <strong>Current Airdrop Balance: </strong>
                <div class="mb-2">
                  {{airdropBalance}}
                </div>
              </div>
              <div class="col-md-6 col-12">
                <strong>Send Airdrop: </strong>
                <div class="mb-2">
                  <input class="form-control" placeholder="target amount" v-model="targetAirdropAmount"/>
                  <br/>
                  <input class="form-control" placeholder="target address" v-model="targetAirdropAddress"/>
                </div>
                <button class="btn btn-info" type="button" @click="sendAirdrop()">Send Airdrop</button>
              </div>
            </div>
          </section>

          <br/>
          <hr/>
          <section>
            <h2>Manage Affiliate Marketing</h2>
            <div class="row">
              <div class="col-md-6 col-12">
                <h3>General Affiliation</h3>
                <div>
                  <strong>Current Affiliate Percent: </strong>
                  <span>{{ affiliateCommission ? affiliateCommission : '' }}%</span>
                </div>
                <br/>
                <i>Set general affiliate commission percent: </i>
                <input class="form-control mb-1" placeholder="commission percent" v-model="newAffiliateCommission">
                <button type="button" class="btn btn-info" @click="setGeneralAffiliateCommission()">Set Affiliate
                  Commission
                </button>
              </div>
              <div class="col-md-6 col-12">
                <h3>Custom Affiliation</h3>
                <input class="form-control mb-1" v-model="customAffiliateAddress" placeholder="wallet address">
                <button class="btn btn-info" type="button" @click="getCustomAffiliateCommission">Get Custom Commission
                </button>
                <div>
                  <strong>Current Affiliate Percent: </strong>
                  <span>{{
                      customAffiliateCommission !== '' ? (customAffiliateCommission !== '0' ? customAffiliateCommission + '%' : 'not set') : ''
                    }}</span>
                </div>
                <br/>
                <i>Set custom affiliate commission percent for entered: </i>
                <input class="form-control mb-1" placeholder="commission percent"
                       v-model="customNewAffiliateCommission">
                <button type="button" class="btn btn-info" @click="setCustomAffiliateCommission()">Set Affiliate
                  Commission
                </button>
              </div>
            </div>
          </section>
        </div>
        <div v-else class="alert alert-danger">You are not authorized</div>
      </div>
    </div>
  </main>
</template>

<style scoped lang="css">
main {
  overflow-x: hidden;
}

</style>
