import Web3 from "web3";
import {PriceFeedAbi} from "@/blockchain/price-feed.abi";

export class ChainlinkPriceApi
{
  public readonly web3:Web3;
  private priceFeedContract;

  constructor()
  {
    const Web3Object = require('web3');
    this.web3 = new Web3Object(
      new Web3Object.providers.HttpProvider(process.env.VUE_APP_RPC_ADDRESS),
    );

    //@ts-ignore
    this.priceFeedContract = new this.web3.eth.Contract(PriceFeedAbi,process.env.VUE_APP_PRICE_FEED_CONTRACT_ADDRESS);
  }

  async getBnbPrice()
  {
    try
    {
      const r = await this.priceFeedContract.methods.latestRoundData().call();
      if (r && r.answer)
        return Number(r.answer/ (10**8))
    }
    catch (e)
    {
      console.log(e)
      return null;
    }
  }
}