<script lang="ts">

import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import {defineComponent} from "vue";

export default defineComponent({
  name: "FeaturedOn",
  data()
  {
    return {
      items: [
        {
          name: 'dappradar',
          url: 'https://dappradar.com/dapp/decentraguys-2'
        },
        {
          name: 'cryptototem',
          url: 'https://cryptototem.com/decentraguys-dece/'
        },
        {
          name: 'coinmarketcap',
          url: 'https://coinmarketcap.com/community/profile/Decentraguys/'
        },
        {
          name: 'coingabbar',
          url: 'https://www.coingabbar.com/de/crypto-ico-details/decentraguys-dece-ico-crypto-token-gaming-project'
        },
        {
          name: 'binplorer',
          url: 'https://binplorer.com/address/0x4ba6f20139c3a68afbf9be7695b52d0845d5aaa9#'
        },
      ]
    }
  },
  methods: {
    getImageSrc(name)
    {
      return require(`@/assets/images/featured-on/${name}.webp`);
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
})
</script>

<template>
  <div class="page" id="FeaturedOn">
    <div class="container">
      <div class="page-titles">Featured On</div>
      <div class="slider">
        <swiper
            :grabCursor="true"
            :centeredSlides="true"
            :slidesPerView="'auto'"
            :loop="true"
            :centered-slides="true"
            :slides-per-view="5"
        >
          <swiper-slide class="featured-on-item" v-for="(item,index) in items" :key="index">
            <a :href="item.url" target="_blank">
              <img :src="getImageSrc(item.name)" :alt="item.name"/>
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.featured-on-item img {
  width: 100%;
}
#FeaturedOn{
  margin-top: 70px;
}
.featured-on-item {
  box-shadow: none !important;
  padding: 30px;
}
</style>