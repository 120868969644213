<script lang="ts">
import {defineComponent} from 'vue'
import Web3Modals from "@/components/Web3Modals.vue";

export default defineComponent({
  name: "Header",
  components: {Web3Modals},
  mounted()
  {
    document.addEventListener('DOMContentLoaded', function ()
    {
      const stickyMenu = document.querySelector('header');
      let scrollPosition = window.scrollY;

      window.addEventListener('scroll', function ()
      {
        scrollPosition = window.scrollY;
        if (stickyMenu)
        {
          // Add or remove 'scrolled' class based on scroll position
          if (scrollPosition > 60)
          {
            stickyMenu.classList.add('scrolled');
          }
          else
          {
            stickyMenu.classList.remove('scrolled');
          }
        }
      });
    });
  }
})
</script>

<template>
  <Web3Modals/>
  <div class="left-back"></div>
  <div class="top-circle">
    <img src="@/assets/images/top_cir.svg" alt="">
  </div>
  <header class="sticky-menu">

    <nav class="navbar navbar-expand-lg w-100 ">
      <div class="wrapper-header px-5">
        <router-link to="/" class="navbar-brand" href="/#top">Decentraguys</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul style="margin: 0 !important;" class="navbar-nav me-auto ">
            <li class="nav-item">
              <a class="nav-link" href="/#gameIntro">Game Intro</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#nft">Monkey and Rare NFTs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="/#roadMap">RoadMap</a>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="/#makeMoney">Make Money</a>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="/#tokonomics">Tokonomics</a>
            </li>
            <li class="nav-item">
              <a class="nav-link " target="_blank" href="https://decentraguys.gitbook.io/decentra-guys-white-paper/">Whitepaper</a>
            </li>
            <li class="nav-item">
              <router-link to="/FAQ" class="nav-link">FAQ</router-link>
            </li>
          </ul>
          <div class=" d-lg-none d-block text-center">
            <router-link to="/BuyChests" class="btn btn-gradient-reverse mb-2" type="submit">Buy Token Chest</router-link>
            <br/>
            <a href="/#buyDgt" class="btn btn-gradient" type="submit">Buy Desired DECE</a>
          </div>
        </div>
        <div class=" d-none d-lg-block text-right">
          <router-link to="/BuyChests" class="btn btn-gradient-reverse mb-2" type="submit">Buy Token Chest</router-link>
          <br/>
          <a href="/#buyDgt" class="btn btn-gradient" type="submit">Buy Desired DECE</a>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped lang="css">
header {
  padding: 5px 0;
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #160040d9;
}

header:after {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
  transition: all 0.5s;
  background: linear-gradient(90deg, #1d2c5f 0%, #160040 25%, #160040 100%);
}

header.scrolled:after {
  opacity: 1;
}

header nav {
  z-index: 101;
}

.nav-link, header {
  color: white !important;
}

.navbar-brand {
  background: -webkit-linear-gradient(99.24deg, #9400FF -13.99%, #3AD9DA 77.28%, #54F5F6 126.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 5%;
//background: linear-gradient(99.24deg, #9400FF -13.99%, #3AD9DA 77.28%, #54F5F6 126.43%);
}

.wrapper-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btn {
  padding-right: 25px;
  padding-left: 25px;
}

.navbar-toggler-icon {
  filter: invert(1);
}

.navbar-nav {
  grid-column-gap: 25px;
}

.top-circle {
  width: 65%;
  position: absolute;
  top: 0;
  right: 4%;
}

.top-circle img {
  width: 100%;
}

.left-back {
  position: absolute;
  top: 0;
  border-radius: 684px;
  opacity: 0.2;
  background: #3BD9DA;
  filter: blur(400px);
  width: 684px;
  height: 684px;
  transform: translateX(-50%) translateY(-50%);
}

.wrapper-header .btn {
  min-width: 140px;
}

.text-right {
  text-align: right;
}
@media screen and (max-width: 480px) {
  #navbarNav {
    position: absolute;
    top: 70px;
    text-align: center;
    background: #160241;
    left: 0;
    padding: 15px;
    width: calc(100% - 30px);
    margin-left: 15px;
    border-radius: 15px;
  }
}
</style>
