import Web3 from "web3";
import {DecentraGuysAbi} from "@/blockchain/decentra-guys.abi";
import {Web3Helper} from "@/blockchain/web3-helper";
import {PriceFeedAbi} from "@/blockchain/price-feed.abi";

export class TokenContractApi
{
  public readonly web3:Web3;
  private contract;
  private priceFeedContract;
  public readonly contractAddress;

  constructor(web3:Web3)
  {
    this.web3 = web3;
    const Web3Object = require('web3');
    const web3Object = new Web3Object(Web3Helper.getWindowWeb3());
    this.contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS;
    console.log('Price Feed: ',process.env.VUE_APP_PRICE_FEED_CONTRACT_ADDRESS)
    this.priceFeedContract = new web3Object.eth.Contract(PriceFeedAbi,process.env.VUE_APP_PRICE_FEED_CONTRACT_ADDRESS);
    console.log('Contract: ',this.contractAddress)
    this.contract = new web3Object.eth.Contract(DecentraGuysAbi,this.contractAddress)
  }

  async getCurrentAccount()
  {
    const accounts = await this.web3.eth.getAccounts();
    return accounts[0];
  }

  async getNativeBalance(address)
  {
    try
    {
      return this.web3.eth.getBalance(address)
    }
    catch (e)
    {
      console.log(e);
      return null;
    }
  }

  async balanceOf(address)
  {
    try
    {
      return this.contract.methods.balanceOf(address).call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async getBnbPrice()
  {
    try
    {
      const r = await this.priceFeedContract.methods.latestRoundData().call();
      if (r && r.answer)
        return Number(r.answer/ (10**8))
    }
    catch (e)
    {
      console.log(e)
      return null;
    }
  }

  async transfer(address,amount)
  {
    try
    {
      return this.contract.methods.transfer(address,amount).send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async devPercent()
  {
    try
    {
      return this.contract.methods.devPercent().call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async airdropPercent()
  {
    try
    {
      return this.contract.methods.airdropPercent().call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async itoPercent()
  {
    try
    {
      return this.contract.methods.itoPercent().call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async gameBoxPercent()
  {
    try
    {
      return this.contract.methods.gameBoxPercent().call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async totalItoAmount()
  {
    try
    {
      return this.contract.methods.totalItoAmount().call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async airdropRemaining()
  {
    try
    {
      return this.contract.methods.airdropRemaining().call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async gameBoxRemaining()
  {
    try
    {
      return this.contract.methods.gameBoxRemaining().call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async canBuyChest(chestId)
  {
    try
    {
      return this.contract.methods.canBuyChest(chestId).call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async buyChest(chestId,referrer,chestPrice)
  {
    console.log(referrer)
    try
    {
      return await this.contract.methods.buyChest(chestId,referrer).send({
        from:await this.getCurrentAccount(),
        value:chestPrice
      })
    }
    catch (e)
    {
      return null;
    }
  }

  async getChests()
  {
    try
    {
      return this.contract.methods.getChests().call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async addChest(_tokens,  _price,  _totalForSale,  _isActive)
  {
    try
    {
      return this.contract.methods.addChest(_tokens,  _price,  _totalForSale,  _isActive).send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async editChest(_id,_tokens,  _price, _totalForSale, _isActive)
  {
    try
    {
      return this.contract.methods.editChest(_id,_tokens,  _price,  _totalForSale,  _isActive).send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async affiliateCommission()
  {
    try
    {
      return this.contract.methods.affiliateCommission().call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }


  // ------------- Admin Methods ---------------//

  async setStakeWallets(wallets,shares)
  {
    try
    {
      return this.contract.methods.setStakeWallets(wallets,shares).send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      console.log(e)
      return null;
    }
  }

  async withdraw(_amount)
  {
    try
    {
      return this.contract.methods.withdraw(this.web3.utils.toWei(String(_amount))).send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      console.log(e)
      return null;
    }
  }

  async transferAirdrop(_targetAddress,_amount)
  {
    try
    {
      _amount = String(_amount)+'00000000';
      return this.contract.methods.transferAirdrop(_targetAddress,_amount).send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      console.log(e)
      return null;
    }
  }

  async activateIto()
  {
    try
    {
      return this.contract.methods.activateIto().send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async deactivateIto()
  {
    try
    {
      return this.contract.methods.deactivateIto().send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async chargeGameBox(_amount)
  {
    try
    {
      return this.contract.methods.chargeGameBox(_amount).send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async setAffiliateCommission(percent)
  {
    try
    {
      return this.contract.methods.setAffiliateCommission(percent).send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async setSpecialCommission(address,percent)
  {
    try
    {
      return this.contract.methods.setSpecialCommission(address,percent).send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async setItoRates(rates)
  {
    try
    {
      return this.contract.methods.setItoRates(rates).send({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async buyWithReferrer(referrer,value)
  {
    try
    {
      return this.contract.methods.buyWithReferrer(referrer).send({
        from:await this.getCurrentAccount(),
        value:this.web3.utils.toWei(String(value))
      })
    }
    catch (e)
    {
      return null;
    }
  }

  async buy(value)
  {
    console.log('BNB Amount: ',this.web3.utils.toWei(String(value)))
    try
    {
      return this.contract.methods.buy().send({
        from:await this.getCurrentAccount(),
        value:this.web3.utils.toWei(String(value))
      })
    }
    catch (e)
    {
      return null;
    }
  }

  async getStakeAddresses()
  {
    try
    {
      return this.contract.methods.getStakeAddresses().call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async convertMsgValueToUSDT2(a)
  {
    try
    {
      return this.contract.methods.convertMsgValueToUSDT2(a).call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      console.log(e)
      return null;
    }
  }

  async getStakeAddressShare(address)
  {
    try
    {
      return this.contract.methods.getStakeAddressShare(address).call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async getStakeInfo()
  {
    try
    {
      const addresses = await this.contract.methods.getStakeAddresses().call({from:await this.getCurrentAccount()})
      const out:{address:string,share:number|null}[] = [];
      if (!addresses || !Array.isArray(addresses))
        return out;
      for (let i=0;i<addresses.length;i++)
      {
        const share = await this.getStakeAddressShare(addresses[i]);
        out.push({
          address:addresses[i],
          share:share?Number(share):null
        })
      }
      return out;
    }
    catch (e)
    {
      return null;
    }
  }

  async getSpecialCommission(address)
  {
    try
    {
      return this.contract.methods.getSpecialCommission(address).call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  //------------- DecentraGuys Methods -------------//

  async getItoDetails()
  {
    try
    {
      const info = await this.contract.methods.getItoDetails().call({from:await this.getCurrentAccount()});
      return {
        totalItoAmount:await this.contract.methods.totalItoAmount().call({from:await this.getCurrentAccount()}),
        itoIsActive:info[0],
        itoCurrentLevel:Number(info[1]),
        itoPercents:info[2].map((str) => Number(str)),
        itoRates:info[3].map((str) => Number(str)),
        itoRemainingAmounts:info[4].map((str) => Number(str))
      }
    }
    catch (e)
    {
      return null;
    }
  }

  async getReferrer(address)
  {
    try
    {
      return this.contract.methods.getReferrer(address).call({from:await this.getCurrentAccount()})
    }
    catch (e)
    {
      return null;
    }
  }

  async isAuthorized(address)
  {
    console.log('AD: ',address)
    try
    {
      const r = await this.contract.methods.isAuthorized(address).call()
      console.log('R: ',r)
      return r;
    }
    catch (e)
    {
      console.log(e)
      return null;
    }
  }


}